import React, { FunctionComponent, memo, useEffect, useRef } from 'react';
import { Locale } from '../../models/locale';
import { IReviewEntry } from '../../models/review-entry';
import { useStore } from '../../store/store';
import { buildCanonicalUrl } from '../../utils/define';
import { Link, useTranslation } from '../../utils/i18n';
import { formatTime } from '../../utils/time';
import PostCommentCount from '../post-comment-count/post-comment-count';
import styles from './review-feature-section.module.scss';

interface IProps {
  featuredPosts: IReviewEntry[];
}

const ReviewFeatureSection: FunctionComponent<IProps> = memo(({ featuredPosts }) => {
  const lang = useStore<Locale>((s) => s.vm.lang);
  const { t } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = `${(ref.current.offsetWidth * 9) / 16}px`;
      const onResize = () => {
        ref.current.style.height = `${(ref.current.offsetWidth * 9) / 16}px`;
      };
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }
  }, []);

  return (
    <div className={`row m-0 p-0 ${styles.featureSection}`}>
      <div className='col-12 p-0 mb-4'>
        <h1>{t('review.popular')}</h1>
      </div>
      {featuredPosts[0] && (
        <div className='col-12 col-sm-12 col-md-12 col-lg-7 p-0'>
          <Link to={`${featuredPosts[0].basePath}-${lang}/`}>
            <div
              ref={ref}
              className={styles.mainPost}
              style={{ backgroundImage: `url(${featuredPosts[0].cover})` }}
            >
              <div className={styles.mainTitle}>
                <h2>{featuredPosts[0].title}</h2>
              </div>
            </div>
          </Link>
        </div>
      )}
      {featuredPosts.length > 0 && (
        <div className={`col-12 col-sm-12 col-md-12 col-lg-5 p-0 ${styles.restPosts}`}>
          <ol>
            {featuredPosts.slice(1).map((p) => (
              <li key={p.basePath}>
                <Link to={`${p.basePath}-${lang}/`}>
                  <h2>
                    {p.title}
                    <br />
                    <small className='text-muted'>
                      <i className='far fa-comment  mr-2' />
                      <PostCommentCount
                        showWording={false}
                        identifier={p.id}
                        url={buildCanonicalUrl(p.basePath, lang)}
                      />
                      &nbsp;&nbsp;|&nbsp;&nbsp;
                    </small>
                    <small className='text-muted '>
                      <i className='far fa-clock mr-2' />
                      {formatTime(p.updatedAt)}
                    </small>
                  </h2>
                </Link>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
});

export default ReviewFeatureSection;
