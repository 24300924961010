import React, { FunctionComponent, memo } from 'react';
import { Locale } from '../../models/locale';
import { buildCanonicalUrl } from '../../utils/define';
import { Link, useTranslation } from '../../utils/i18n';
import { fromNow } from '../../utils/time';
import PostCommentCount from '../post-comment-count/post-comment-count';
import styles from './review-post-item.module.scss';

interface IProps {
  id: string;
  title: string;
  lang: Locale;
  path: string;
  cover: string;
  summary: string;
  createdAt: string;
  updatedAt: string;
}

const ReviewPostItem: FunctionComponent<IProps> = memo(
  ({ updatedAt, path, lang, title, cover, id, summary, createdAt }) => {
    const { t } = useTranslation();
    const timeStr = fromNow(createdAt);
    const canonicalUrl = buildCanonicalUrl(path, lang);

    return (
      <article className={styles.reviewPostItem}>
        <div className='entry-header'>
          <div className={styles.postIntro}>
            <Link to={`${path}/`}>
              <img src={cover} alt={`${title}`} />
            </Link>
          </div>
          <h2 className={styles.entryTitle}>
            <Link to={`${path}/`} rel='bookmark'>
              {title}
            </Link>
          </h2>
          <div className={styles.postDetails}>
            <div className='text-muted'>{timeStr} ago</div>
            <div className='text-muted'>
              <Link to={`${path}#comments`}>
                <PostCommentCount showWording url={canonicalUrl} identifier={id} />
              </Link>
            </div>
          </div>
          <div className={styles.postInfo}>
            <p>{summary}</p>
          </div>
          <div className={styles.postFooter}>
            <Link to={`${path}/`}>{t('post.read_more')}</Link>
          </div>
        </div>
      </article>
    );
  },
);

export default ReviewPostItem;
