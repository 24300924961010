import React, { FunctionComponent, useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import Layout from '../components/layout/layout';
import MultiLangTags from '../components/multi-lang-tags/multi-lang-tags';
import OpenGraphTags from '../components/open-graph-tags/open-graph-tags';
import ReviewFeatureSection from '../components/review-feature-section/review-feature-section';
import ReviewPostItem from '../components/review-post-item/review-post-item';
import SeoMetaTags from '../components/seo-meta-tags/seo-meta-tags';
import siteCover from '../images/site-cover.jpg';
import { Locale } from '../models/locale';
import { IReviewEntry, ReviewCategory } from '../models/review-entry';
import { fetchReviews } from '../services/cms';
import { buildArticleDatePath, buildCanonicalUrl } from '../utils/define';
import { useTranslation } from '../utils/i18n';
import styles from './review.module.scss';

interface IProps {
  pageContext: {
    category: ReviewCategory;
    featuredPosts: IReviewEntry[];
    reviewPosts: IReviewEntry[];
    intl: { language: Locale };
  };
}
const ReviewsPage: FunctionComponent<IProps> = ({ pageContext }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<IProps['pageContext']['reviewPosts']>([
    ...pageContext.reviewPosts.filter((p) => p.lang === pageContext.intl.language),
  ]);
  const [showLoadMore, setShowLoadMore] = useState(true);

  const loadMoreItems = async (reset?: boolean) => {
    const { entries, hasMore } = await fetchReviews({
      lang: pageContext.intl.language,
      count: 10,
      skip: items.length,
      category: pageContext.category,
    });

    const newItems = entries.map((review) => {
      const date = new Date(review.createdAt);
      return {
        basePath: `${buildArticleDatePath(date)}/${review.slug}`,
        path: `${buildArticleDatePath(date)}/${review.slug}-${review.lang}`,
        ...review,
      };
    });
    setItems([...items, ...newItems]);
    setShowLoadMore(hasMore);
  };

  const masonryBreakPoints = {
    default: 2,
    768: 1,
  };
  const pageDescription = `Explore the things we loved. Foods review, gadgets review, games review`;
  const path =
    pageContext.category === ReviewCategory.All ? '/review' : `/review/${pageContext.category}`;
  const title = `${
    pageContext.category === ReviewCategory.All
      ? t('common.header.review')
      : `${t(`review.category.${pageContext.category}`)} ${t('common.header.review')}`
  } | Beat Panda`;
  return (
    <Layout lang={pageContext.intl.language} basePath={path}>
      <SeoMetaTags
        lang={pageContext.intl.language}
        title={title}
        description={pageDescription}
        canonicalUrl={buildCanonicalUrl(path, pageContext.intl.language)}
      />
      <MultiLangTags path={path} basePath={path} />
      <OpenGraphTags
        url={buildCanonicalUrl(path, pageContext.intl.language)}
        title={title}
        description={pageDescription}
        image={siteCover}
        locale={pageContext.intl.language}
      />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <ReviewFeatureSection
              featuredPosts={pageContext.featuredPosts.filter(
                (p) => p.lang === pageContext.intl.language,
              )}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
            <div className='col-md-12'>
              <Masonry
                breakpointCols={masonryBreakPoints}
                className={styles.reviewPostMasonry}
                columnClassName={styles.reviewPostMasonryColumn}
              >
                {items.map((post) => (
                  <ReviewPostItem
                    key={post.id}
                    id={post.id}
                    title={post.title}
                    lang={pageContext.intl.language}
                    path={post.path}
                    cover={post.cover}
                    summary={post.summary}
                    createdAt={post.createdAt}
                    updatedAt={post.updatedAt}
                  />
                ))}
              </Masonry>
            </div>
            {showLoadMore && (
              <div className='col-12 d-flex justify-content-center align-items-center'>
                <button onClick={() => loadMoreItems(false)}>{t('post.load_more')}</button>
              </div>
            )}
          </div>
          {/*<div className='col-12 col-sm-12 col-md-3 col-lg-2 p-0'>*/}
          {/*  <ReviewCategorySection />*/}
          {/*</div>*/}
        </div>
      </div>
    </Layout>
  );
};

export default ReviewsPage;
